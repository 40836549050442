// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sb-folders-list-item {
    display: flex;
    gap: 5px;
    color: white;
    font-size: 11px;
    padding: 0.4rem .5rem;
    cursor: pointer;

    &:hover {
        background-color: #6a6a6a;
    }
}

.no-folder-content {
    padding-left: 24px;
}

.active-list-item {
    background-color: #6a6a6a;
}

.non-expanable {
    min-width: 12px;
}

.folder-collapsible {
    margin-left: 10px;
}

.sb-folder-details {
    display: flex;
    gap: 8px;
}

.folder-rotate-down {
    animation: rotateFolderDown 0.5s linear forwards;
}

@keyframes rotateFolderDown {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

.folder-rotate-right {
    animation: rotateRight 0.5s linear forwards;
}

@keyframes rotateRight {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/virtual/Explorer/FolderTree/foldertree.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,eAAe;;IAEf;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI;QACI,wBAAwB;IAC5B;;IAEA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".sb-folders-list-item {\n    display: flex;\n    gap: 5px;\n    color: white;\n    font-size: 11px;\n    padding: 0.4rem .5rem;\n    cursor: pointer;\n\n    &:hover {\n        background-color: #6a6a6a;\n    }\n}\n\n.no-folder-content {\n    padding-left: 24px;\n}\n\n.active-list-item {\n    background-color: #6a6a6a;\n}\n\n.non-expanable {\n    min-width: 12px;\n}\n\n.folder-collapsible {\n    margin-left: 10px;\n}\n\n.sb-folder-details {\n    display: flex;\n    gap: 8px;\n}\n\n.folder-rotate-down {\n    animation: rotateFolderDown 0.5s linear forwards;\n}\n\n@keyframes rotateFolderDown {\n    from {\n        transform: rotate(0deg);\n    }\n\n    to {\n        transform: rotate(90deg);\n    }\n}\n\n.folder-rotate-right {\n    animation: rotateRight 0.5s linear forwards;\n}\n\n@keyframes rotateRight {\n    from {\n        transform: rotate(90deg);\n    }\n\n    to {\n        transform: rotate(0deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
